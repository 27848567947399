import React, {useState, useEffect} from 'react'
import {graphql} from 'gatsby';
import Default from 'gatsby-theme-carbon/src/templates/Default';
import {StructuredListWrapper, StructuredListRow, StructuredListCell, StructuredListBody, Search} from 'carbon-components-react';

export default function Glossary({pageContext, location, data}) {
	pageContext = {...pageContext, frontmatter:
		{
			title: `Glossary`
		}
	};

	const [terms, setTerms] = useState(data.allTaxonomyTermGlossary.edges)
	const [searchQuery, setSearchQuery] = useState('')

	useEffect(() => {
		let sq = typeof searchQuery !== 'undefined' ? `${searchQuery}`.trim() : '';
		if(sq.length === 0) {
			setTerms(data.allTaxonomyTermGlossary.edges);
		} else {
			let filtered = data.allTaxonomyTermGlossary.edges.filter(({node}) => {
				let nameMatch = `${node.name}`.toLowerCase().includes(`${sq}`.toLowerCase())
				let descriptionMatch = `${node.description.processed}`.toLowerCase().includes(`${sq}`.toLowerCase())
				return nameMatch || descriptionMatch
			})
			setTerms(filtered)
		}
	}, [searchQuery])

	return <Default pageContext={pageContext} location={location}>
		<Search size='xl' 
			labelText="Filter terms"
			id="search-glossary"
			placeHolderText="Search"
			onChange={(e) => setSearchQuery(e.target.value)}
		/>
		<StructuredListWrapper ariaLabel="Glossary Terms" className="odds-glossary">
			<StructuredListBody>
				{terms.map(({node}) => <StructuredListRow tabIndex={0} key={node.drupal_internal__tid}>
					<StructuredListCell>
						<strong>{node.name}</strong>
					</StructuredListCell>
					<StructuredListCell>
						<div dangerouslySetInnerHTML={{__html: node.description.processed}}></div>
					</StructuredListCell>
				</StructuredListRow>)}
			</StructuredListBody>
		</StructuredListWrapper>
	</Default> ;
}


export const query = graphql`
{
	allTaxonomyTermGlossary(sort: {fields: name, order: ASC}) {
		edges {
			node {
				drupal_internal__tid
				name
				description {
					processed
				}
			}
		}
	}
}
`